// eslint-disable-next-line import/no-cycle
import requestService from "../requestService";

const prefix = '/crms';

export default {
  async getCrm(crmId) {
    const response = await requestService.get(`${prefix}/${crmId}`);
    return response?.data?.data;
  },
  async getCrms(params) {
    const response = await requestService.get(`${prefix}`,
        {...params}
        );
    return response?.data;
  },
  async addCrm(payload) {
    const response = await requestService.post(`${prefix}`, payload);
    return response?.data?.data;
  },
  async updateCrm(crmId, payload) {
    const response = await requestService.post(`${prefix}/${crmId}`, payload);
    return response?.data?.data;
  },
  async deleteCrm(crmId, payload) {
    const response = await requestService.delete(`${prefix}/${crmId}`, payload);
    return response?.data?.data;
  },
  async addStatus(crmId, payload) {
    const response = await requestService.post(`${prefix}/${crmId}/statuses/bind`, payload, {}, false);
    return response?.data?.data;
  },
  async deleteStatus(crmId, payload) {
    const response = await requestService.post(`${prefix}/${crmId}/statuses/unbind`, payload);
    return response?.data?.data;
  },
  async selectStatus(crmId) {
    const response = await requestService.patch(`${prefix}/${crmId}/select`, {}, {}, false);
    return response?.data?.data;
  },
};
