<template>
  <div class="mb-2">
    <v-progress-linear
        v-if="loading"
        indeterminate
        color="orange"
        absolute
        top
    ></v-progress-linear>
    <v-card outlined color="transparent">
      <v-card-title class="pa-0">
        <v-row
            :class="[
            'align-center',
            'custom-v-row',
            $vuetify.breakpoint.smAndDown ? 'flex-column-reverse' : '',
          ]"
        >
          <v-col class="custom-v-col py-0">
            <v-col cols="auto">
              {{ $t("sideBar.admin_panel") }}
            </v-col>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="pa-0" v-if="permissions.can_add_edit_geo_users">
        <v-card class="pa-0">
          <v-card-title>
            {{ $t("sideBar.users") }}
          </v-card-title>
          <v-card-text>
            <v-data-table
                :headers="usersHeaders"
                :items="usersList"
                class="mt-4"
                dense
                outlined
                hide-default-footer
            >
              <template v-slot:item.editAction="{ item }">
                <v-btn icon @click="openEditUserDialog(item)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-card-text>

      <v-card-text class="pa-0 mt-4">
        <v-card class="mt-3" v-if="permissions.can_add_edit_geo_users">
          <v-card-title>
            {{ $t("form.GEO") }}
          </v-card-title>

          <v-row
              :class="[
            'align-center',
            'custom-v-row',
            'px-5 pt-3 mx-3'
          ]"
          >
            <v-row class="pb-2" v-if="permissions.can_add_edit_geo_users">
              <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 2">
                {{ $t("form.add_location") }}
              </v-col>
              <v-text-field
                  :class="$vuetify.breakpoint.mobile ? 'pb-3 ml-2' : 'ml-2'"
                  outlined
                  dense
                  color="primary"
                  v-model="geo.name"
                  :label="$t('form.country')"
                  :maxlength="25"
                  hide-details="auto"
                  @keypress="validationForNickName"
                  @paste="validationForNickName"
              />
              <v-text-field
                  class="ml-2"
                  outlined
                  dense
                  color="primary"
                  v-model="geo.country_code"
                  :label="$t('form.country_code')"
                  :maxlength="2"
                  hide-details="auto"
                  @keypress="validationForCountryCode"
                  @paste="validationForCountryCode"
              />
              <v-btn
                  icon
                  :color="isFormValidGeo ? ($vuetify.theme.dark ? 'white' : 'black') : 'grey'"
                  @click="addGeo"
                  :loading="locationLoader"
                  :disabled="!isFormValidGeo"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-row>
          </v-row>
          <v-card-text>
            <v-data-table
                :headers="geoHeaders"
                :items="geoList"
                :items-per-page="geoList.length"
                class="mt-4"
                dense
                outlined
                hide-default-footer
            >
              <template v-slot:item.action="{ item }">
                <v-btn icon @click="showDeletedGeoDialog(item)">
                  <v-icon color="red">mdi-delete</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>

        <v-card class="mt-3" v-if="permissions.can_add_edit_category">
          <v-card-title>
            {{ $t("form.CATEGORIES") }}
          </v-card-title>
          <v-row
              :class="[
            'align-center',
            'custom-v-row',
            'px-5 pt-3 mx-3'
          ]"
          >
          <v-row class="py-0" v-if="permissions.can_add_edit_category">
            <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 2">
              {{ $t("form.add_a_category") }}
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mobile ? 10 : 'auto'" class="pl-0">
              <v-text-field
                  class="ml-2"
                  outlined
                  dense
                  color="primary"
                  v-model="category.name"
                  :label="$t('offersCatalog.category')"
                  :maxlength="25"
                  hide-details="auto"
                  @keypress="validationForNickName"
                  @paste="validationForNickName"
              />
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mobile ? 10 : 'auto'" class="d-flex align-center justify-center px-0 py-0">
              <v-col
                  cols="auto"
                  class="px-1"
                  v-for="icon in iconsList"
                  :key="icon"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                        outlined
                        :color="$vuetify.theme.dark ? 'rgba(255, 255, 255, 0.12)' : ''"
                        style="border-radius: 4px"
                        :class="{ 'v-custom-tonal-btn--primary': isChosedIcon(icon) }"
                        @click="changeIcon(icon)"
                    ><v-icon>{{ icon }}</v-icon></v-btn>
                  </template>
                  {{ icon.replace('mdi-', '') }}
                </v-tooltip>
              </v-col
              >
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 'auto'" class="d-flex align-center justify-start">
              <v-btn
                  icon
                  :color="isFormValidCategory ? ($vuetify.theme.dark ? 'white' : 'black') : 'grey'"
                  @click="addCategory"
                  :loading="categoryLoader"
                  :disabled="!isFormValidCategory"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          </v-row>

          <v-card-text>
            <v-data-table
                :headers="categoryHeaders"
                :items="categoryList"
                :items-per-page="categoryList.length"
                class="mt-4"
                dense
                outlined
                hide-default-footer
            >
              <template v-slot:item.action="{ item }">
                <v-btn icon @click="showDeletedCategoryDialog(item)">
                  <v-icon color="red">mdi-delete</v-icon>
                </v-btn>
              </template>
              <template v-slot:[`item.icon`]="{ item }">
                <v-icon>{{ item.icon }}</v-icon>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>

        <v-card class="mt-3" v-if="permissions.can_add_edit_category">
          <v-card-title>
            {{ "CRM" }}
          </v-card-title>

          <v-row
              :class="[
            'align-center',
            'custom-v-row',
            'px-5 pt-3 mx-3'
          ]"
          >
            <v-row cols="9" v-if="permissions.can_add_edit_geo_users">
              <v-col :cols="$vuetify.breakpoint.mobile ? 12 : 2">
                {{ $t("form.add_crm") }}
              </v-col>
              <v-text-field
                  :class="$vuetify.breakpoint.mobile ? 'pb-3 ml-2' : 'ml-2'"
                  outlined
                  dense
                  color="primary"
                  v-model="crm.name"
                  :label="$t('form.title')"
                  :maxlength="25"
                  hide-details="auto"
              />
              <v-text-field
                  :class="$vuetify.breakpoint.mobile ? 'pb-3 ml-2' : 'ml-2'"
                  outlined
                  dense
                  color="primary"
                  v-model="crm.url"
                  :label="'URL'"
                  hide-details="auto"
              />
              <v-text-field
                  :class="$vuetify.breakpoint.mobile ? 'pb-3 ml-2' : 'ml-2'"
                  outlined
                  dense
                  color="primary"
                  v-model="crm.input_token"
                  :label="'Input TOKEN'"
                  hide-details="auto"
              />
              <v-text-field
                  :class="$vuetify.breakpoint.mobile ? 'pb-3 ml-2' : 'ml-2'"
                  outlined
                  dense
                  color="primary"
                  v-model="crm.output_token"
                  :label="'Output TOKEN'"
                  hide-details="auto"
              />
              <v-btn
                  icon
                  :color="isFormValidCrm ? ($vuetify.theme.dark ? 'white' : 'black') : 'grey'"
                  @click="addCrm"
                  :disabled="!isFormValidCrm"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-row>
          </v-row>

          <v-card-text>
            <v-data-table
                :headers="crmHeaders"
                :items="crmList"
                :items-per-page="crmList.length"
                class="mt-4"
                dense
                outlined
                hide-default-footer
            >
              <template v-slot:item.input_token="{ item }">
                <span
                    class="token-mask"
                    @mouseover="showToken = item.id"
                    @mouseleave="showToken = null"
                >
                  {{ showToken === item.id ? item.input_token : '*****************************************' }}
                </span>
              </template>
              <template v-slot:item.output_token="{ item }">
                <span
                    class="token-mask"
                    @mouseover="showToken = item.id"
                    @mouseleave="showToken = null"
                >
                  {{ showToken === item.id ? item.output_token : '*****************************************' }}
                </span>
              </template>
              <template v-slot:item.add_status="{ item }">
                <v-row v-if="item.statuses.length && hasAllRequiredStatuses(item)" justify="center" align="center">
                  <v-checkbox
                      @click.prevent="selectCrmStatus(item)"
                      v-model="item.is_selected"
                      hide-details
                      dense
                      color="primary"
                      :false-value="0"
                      :true-value="1"
                      class="centered-checkbox pl-2"
                      :disabled="item.is_selected == 1"
                  ></v-checkbox>
                </v-row>
                <v-menu v-else v-model="menu[item.id]" offset-y right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="orange" icon small v-bind="attrs" v-on="on">
                      <v-icon size="20">mdi-alert</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-text>
                      {{ 'Статуси відсутні або не налаштовані' }}
                    </v-card-text>
                  </v-card>
                </v-menu>
              </template>
              <template v-slot:item.action="{ item }">
                <v-btn icon @click="showDeletedCrmDialog(item)">
                  <v-icon color="red">mdi-delete</v-icon>
                </v-btn>
              </template>
              <template v-slot:item.editBtn="{ item }">
                <v-btn icon @click="openEditCrmDialog(item)">
                  <v-icon color="grey">mdi-pencil</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
    <deleteConfirmDialog
        :visible="showDeleteItemDialog"
        @close="showDeleteItemDialog = false"
        @delete="removeItem"
    />
    <edit-user-dialog v-if="showEditUserDialog" :visible="showEditUserDialog" :user="user" @close="showEditUserDialog = false" @updateUser="updateUser" @deleteUser="deleteUser"/>
    <crm-edit-dialog v-if="showEditCrmDialog" :visible="showEditCrmDialog" :crmItem="crmItem" @close="showEditCrmDialog = false" @updateCrm="updateCrm" @deleteCrm="deleteCrm"/>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import notifications from "@/mixins/notifications";
import DeleteConfirmDialog from "@/components/dialog/deleteConfirmDialog.vue";
import usersService from "@/services/request/users/usersService";
import AddEditOfferDialog from "@/components/offersCatalog/addEditOfferDialog.vue";
import EditUserDialog from "@/components/adminPanel/editUserDialog.vue";
import {validationForCountryCode, validationForNickName} from "@/mixins/helpers";
import geoService from "@/services/request/geo/geoService";
import offerCategoriesService from "@/services/request/offerCategories/offerCategoriesService";
import user from "@/mixins/user";
import loader from "@/mixins/loader";
import crmService from "@/services/request/crm/crmService";
import CrmEditDialog from "@/components/adminPanel/editCrmDialog.vue";

export default {
  components: {CrmEditDialog, EditUserDialog, AddEditOfferDialog, DeleteConfirmDialog },
  mixins: [notifications, user, loader],
  data: () => ({
    loading: false,
    usersList: [],
    showEditUserDialog: false,
    showEditCrmDialog: false,
    user: {},
    crmItem: {},
    menu: {},
    showToken: null,
    geo: {
      name: '',
      country_code: '',
    },
    crm: {
      name: '',
      url: '',
      input_token: '',
      output_token: '',
    },
    category: {
      name: '',
      icon: ''
    },
    iconsList: [
      "mdi-pencil",
      "mdi-file-document",
      "mdi-folder",
      "mdi-cloud-outline",
      "mdi-alarm",
      "mdi-apps",
      "mdi-application-brackets",
      "mdi-database",
      "mdi-google",
      "mdi-google-drive",
    ],
    requiredStatuses: [
      "new",
      "missed_call",
      "in_progress",
      "confirmed",
      "rejected",
      "shipped",
      "not_purchased",
      "purchased",
    ],
    geoList: [],
    categoryList: [],
    streamsList: [],
    crmList: [],
    showDeleteItemDialog: false,
    item: {},
    categoryLoader: false,
    locationLoader: false
  }),
  computed: {
    ...mapGetters(["currentLanguage"]),
    usersHeaders() {
      return [
        { text: this.$t('table.actions'), value: 'editAction', sortable: false },
        { text: this.$t('table.user_name'), value: 'name' },
        { text: this.$t('table.email'), value: 'email' },
        { text: this.$t('table.phone_number'), value: 'phone_number' },
        { text: this.$t('table.role'), value: 'role' },
      ];
    },
    isFormValidGeo() {
      return this.geo.name.trim() !== '' && this.geo.country_code.trim() !== '';
    },
    isFormValidCategory() {
      return this.category.name.trim() !== '' && this.category.icon.trim() !== '';
    },
    isFormValidCrm() {
      return this.crm.name.trim() !== '' && this.crm.url.trim() !== '' && this.crm.input_token.trim() !== '' && this.crm.output_token.trim() !== '' && this.validateURL();
    },
    ...mapGetters(["currentLanguage"]),
    geoHeaders() {
      return [
        { text: this.$t('settings.name'), value: 'name' },
        { text: this.$t('settings.country_code'), value: 'country_code' },
        { text: this.$t('settings.actions'), align: "end", value: 'action', sortable: false }
      ];
    },
    categoryHeaders() {
      return [
        { text: this.$t('settings.name'), value: 'name' },
        { text: this.$t('settings.icon'), value: 'icon' },
        { text: this.$t('settings.actions'), align: "end", value: 'action', sortable: false }
      ];
    },
    crmHeaders() {
      return [
        { text: '', value: 'add_status', align: "center", sortable: false },
        { text: this.$t('profile.editing'), value: 'editBtn', align: "center", sortable: false },
        { text: this.$t('settings.name'), value: 'name' },
        { text: "URL", value: 'url'},
        { text: "Input Token", value: 'input_token' },
        { text: "Output Token", value: 'output_token' },
      ];
    },
  },
  methods: {
    validationForNickName,
    validationForCountryCode,
    hasAllRequiredStatuses(item) {
      return this.requiredStatuses.every(requiredStatus =>
          item.statuses.some(status => status.system_status === requiredStatus)
      );
    },
    async selectCrmStatus(newItem) {
      try {
        this.crmList.forEach(item => {
          if(item.id != newItem.id) {
            item.is_selected = 0;
          }
        });
        const res = await crmService.selectStatus(newItem.id);
        this.setSuccessNotification(this.$t("form.addSuccess"));
      } catch(e) {
        this.setErrorNotification(e.error.description)
      }
    },
    changeIcon(icon) {
      this.category.icon = icon;
    },
    isChosedIcon(icon) {
      return this.category.icon == icon;
    },
    async addGeo() {
      try {
        this.locationLoader = true;
        const exists = this.geoList.some(g => g.name === this.geo.name || g.country_code === this.geo.country_code);
        if (exists) {
        this.setErrorNotification(this.$t("form.duplicateGeoError"));
        this.geo.name = '';
        this.geo.country_code = '';
        return;
        }
        const formData = new FormData();
        formData.append("name", this.geo.name);
        formData.append("country_code", this.geo.country_code);
        const geos = await geoService.addGeo(formData);
        this.geoList.unshift(geos);
        this.geo.name = '';
        this.geo.country_code = '';
        this.locationLoader = false;
        this.setSuccessNotification(this.$t("form.addSuccess"));
      } catch (e) {
        this.locationLoader = false;
        this.setErrorNotification(e.message);
      }
    },
    async addCategory() {
      try {
        this.categoryLoader = true;
        const exists = this.categoryList.some(c => c.name === this.category.name);
        if (exists) {
        this.setErrorNotification(this.$t("form.duplicateCategoryError"));
        this.category.name = '';
        this.category.icon = '';
        return;
        }
        const formData = new FormData();
        formData.append("name", this.category.name);
        formData.append("icon", this.category.icon);
        const categories = await offerCategoriesService.addOfferCategories(formData);
        this.categoryList.unshift(categories);
        this.category.name = '';
        this.category.icon = '';
        this.setSuccessNotification(this.$t("form.addSuccess"));
        this.categoryLoader = false;
      } catch (e) {
        this.categoryLoader = false;
        this.setErrorNotification(e.message);
      }
    },
    async addCrm() {
      try {
        const formData = new FormData();
        formData.append("name", this.crm.name);
        formData.append("url", this.crm.url);
        formData.append("input_token", this.crm.input_token);
        formData.append("output_token", this.crm.output_token);
        const categories = await crmService.addCrm(formData);
        this.crmList.unshift(categories);
        this.crm.name = '';
        this.crm.url = '';
        this.crm.input_token = '';
        this.crm.output_token = '';
        this.setSuccessNotification(this.$t("form.addSuccess"));
      } catch (e) {
        this.setErrorNotification(e.message);
      }
    },
    async fetchGeoList() {
      try {
        this.geoList = await geoService.getGeo();
      } catch (e) {
        console.log(e);
      }
    },
    async fetchCategoryList() {
      try {
        this.categoryList = await offerCategoriesService.getOfferCategories();
      } catch (e) {
        console.log(e);
      }
    },
    async fetchCrmList() {
      try {
        const response = await crmService.getCrms();
        this.crmList = response.data
      } catch (e) {
        console.log(e);
      }
    },
    async deleteGeo(item) {
      try {
        await geoService.deleteGeo(item.id);
        this.setSuccessNotification(this.$t("form.deleteSuccess"));
        this.fetchGeoList();
        this.item = {};
      } catch (e) {
        console.log(e);
      }
    },
    async deleteCategory(item) {
      try {
        await offerCategoriesService.deleteOfferCategories(item.id);
        this.setSuccessNotification(this.$t("form.deleteSuccess"));
        this.fetchCategoryList();
        this.item = {};
      } catch (e) {
        console.log(e);
      }
    },
    showDeletedGeoDialog(item) {
      this.resetItem();
      this.item = { name: item.name, country_code: item.country_code, id: item.id };
      this.showDeleteItemDialog = true;
    },
    showDeletedCategoryDialog(item) {
      this.resetItem();
      this.item = { name: item.name, icon: item.icon, id: item.id };
      this.showDeleteItemDialog = true;
    },
    showDeletedCrmDialog(item) {
      this.resetItem();
      this.item = { name: item.name, url: item.url, token: item.token, id: item.id };
      this.showDeleteItemDialog = true;
    },
    resetItem() {
      this.item = {};
    },
    async removeItem() {
      if (this.item.country_code) {
        await this.deleteGeo(this.item);
      } else if(this.item.icon) {
        await this.deleteCategory(this.item);
      }
    },
    openEditUserDialog(item) {
      this.user = item;
      this.showEditUserDialog = true;
    },
    openEditCrmDialog(item) {
      this.crmItem = item;
      this.showEditCrmDialog = true;
    },
    async fetchUsers() {
      try {
        this.usersList = await usersService.getUsers();
      } catch (e) {
        console.log(e);
      }
    },
    updateUser(updatedUser) {
      const searchItemIndex = this.usersList.findIndex(
        (item) => item.id === updatedUser.id
      );
      this.$set(this.usersList, searchItemIndex, updatedUser);
    },
    async updateCrm(updatedCrm) {
      const searchItemIndex = this.crmList.findIndex(
        (item) => item.id === updatedCrm.id
      );
      this.$set(this.crmList, searchItemIndex, updatedCrm);
      await this.fetchCrmList();
    },
    async deleteUser(item) {
      try {
        await usersService.deleteUser(item.id);
        const index = this.usersList.findIndex((user) => user.id === item.id);

        if (index !== -1) {
          this.usersList.splice(index, 1);
        }
        this.setSuccessNotification(this.$t("profile.successfully_deleted"));
        this.showEditUserDialog = false;
      } catch (e) {
        console.log(e);
      }
    },
    async deleteCrm(item) {
      try {
        await crmService.deleteCrm(item.id);
        const index = this.crmList.findIndex((crm) => crm.id === item.id);
        if (index !== -1) {
          this.crmList.splice(index, 1);
        }
        this.showEditCrmDialog = false;
      } catch (e) {
        console.log(e);
      }
    },
    validateURL() {
      const urlPattern = /^(https?:\/\/)/;
      return urlPattern.test(this.crm.url);
    },
  },
  async mounted() {
    this.setLoading(true);
    if(!this.permissions.can_add_edit_geo_users) {
      await this.fetchCategoryList();
    } else {
      await this.fetchCategoryList();
      await this.fetchCrmList();
      await this.fetchUsers();
      await this.fetchGeoList();
    }
    this.setLoading(false);
  },
};
</script>
<style scoped>
.custom-v-row {
  margin-bottom: 6px;
  margin-top: 6px;
}
.centered-checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto; /* Центрування в контейнері */
}
</style>
