<template>
  <v-dialog content-class="cross__dialog" v-model="visible" max-width="750px" min-height="350px" persistent @click:outside="$emit('close')">
    <v-card>
      <v-fade-transition>
        <v-progress-linear v-if="loading" absolute top indeterminate color="orange"></v-progress-linear>
      </v-fade-transition>

      <v-slide-x-transition>
        <div class="cross__inner">
          <v-btn fab small elevation="0" @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-slide-x-transition>

      <v-card-title class="justify-center">
        {{ $t('form.statuses') }}
        <v-btn
            style="position: absolute; left: 15px; top: 15px"
            class="btn-orange-bg"
            @click="addStatusRow"
            :disabled="openAddRow"
        >
          {{ $t('btn.add') }} {{ $t('form.status') }}
        </v-btn>
      </v-card-title>

      <v-card-text class="task-list-container">
        <v-row v-if="openAddRow" class="align-center my-3">
          <v-col cols="6">
            <v-text-field
                dense
                :label="$t('form.number')"
                v-model="status.crm_status_id"
                :placeholder="$t('form.number')"
                @keypress="validationForNumbers"
                @paste="validationForNumbers"
            />
          </v-col>
          <v-col cols="4">
            <v-autocomplete
                dense
                :label="$t('form.statuses')"
                :items="statusesList"
                v-model="status.system_status"
                :placeholder="$t('form.status')"
            />
          </v-col>
          <v-col cols="2" class="d-flex justify-center">
            <v-btn
                icon
                color="green"
                @click="saveStatus(status)"
                :disabled="!status.system_status || !status.crm_status_id"
            >
              <v-icon>mdi-check</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-row
            style="height: 400px; overflow-y: auto; overflow-x: hidden;"
            justify="space-between"
            align="start"
            class="ma-0 pa-0 mt-3"
            v-if="userStatuses.length"
        >
          <v-col class="mt-5 mx-0 mb-0 pa-0" cols="12">
            <v-row
                v-for="(userStatus, index) in userStatuses"
                :key="userStatus.id || index"
                class="align-center ma-3 pa-3 d-flex justify-between"
                style="border: 1px solid rgba(0, 0, 0, 0.1); border-radius: 8px; background-color: #f9f9f9;"
            >
              <v-col :cols="$vuetify.breakpoint.width <= 681 ? 12 : 4" class="pa-0">
                <div class="font-weight-bold text-truncate" style="color: #4caf50;">
                  <span class="font-weight-medium">{{ $t('form.number') }}:</span> {{ userStatus.crm_status_id }}
                </div>
              </v-col>

              <v-col :cols="$vuetify.breakpoint.width <= 681 ? 12 : 4" class="pa-0">
                <div class="font-weight-bold text-truncate" style="color: #2196f3;">
                  <span class="font-weight-medium">{{ $t('form.status') }}:</span> {{ $t(`form.${userStatus.system_status}`) }}
                </div>
              </v-col>

              <v-col :cols="$vuetify.breakpoint.width <= 681 ? 12 : 4" class="pa-0 d-flex justify-end">
                <v-btn
                    icon
                    color="red"
                    @click="openShowDeleteDialog(userStatus)"
                    style="background-color: rgba(255, 0, 0, 0.1);"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-else style="height: 400px;" justify="center" class="mb-1 mt-3">
          <v-col cols="auto">{{ 'Немає обраних статусів' }}</v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <deleteConfirmDialog
        :visible="showDeleteDialog"
        @close="showDeleteDialog = false"
        @delete="deleteStatus"
    />
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import notifications from "@/mixins/notifications";
import crmService from "@/services/request/crm/crmService";
import DeleteConfirmDialog from "@/components/dialog/deleteConfirmDialog.vue";
import {validationForNumbers} from "@/mixins/helpers";

export default {
  components: {DeleteConfirmDialog},
  mixins: [notifications],
  data() {
    return {
      userStatuses: [],
      status: {
        crm_status_id: "",
        system_status: ""
      },
      loading: false,
      openAddRow: false,
      showDeleteDialog: false,
      deletedStatus: {}
    };
  },
  props: {
    visible: Boolean,
    inputCrm: {
      required: true,
      type: Object,
    },
  },
  methods: {
    validationForNumbers,
    openShowDeleteDialog(item) {
      this.showDeleteDialog = true;
      this.deletedStatus = item;
    },
    addStatusRow() {
      this.openAddRow = true;
    },
    async deleteStatus() {
      try {

        let formData = new FormData();
        formData.append('crm_status_id', this.deletedStatus.crm_status_id);

        await crmService.deleteStatus(this.inputCrm.id, formData);
        const index = this.userStatuses.findIndex((status) => status.crm_status_id === this.deletedStatus.crm_status_id);

        if (index !== -1) {
          this.userStatuses.splice(index, 1);
        }

        this.inputCrm.statuses = [...this.userStatuses];

        this.deletedStatus = {};

        this.setSuccessNotification(this.$t("profile.successfully_deleted"));

        this.showDeleteDialog = false;
      } catch (e) {
        console.log(e);
      }
    },
    async saveStatus(status) {
      try {
        const formData = new FormData();

        formData.append(`system_status`, status.system_status);
        formData.append(`crm_status_id`, status.crm_status_id);

        const res = await crmService.addStatus(this.inputCrm.id, formData);

        const newStatus = { ...status };
        this.userStatuses.unshift(newStatus);

        this.status.crm_status_id = "";
        this.status.system_status = "";

        this.openAddRow = false;
        this.inputCrm.statuses = [...this.userStatuses];

        console.log(res);
      } catch(e) {
        this.setErrorNotification(e.error.description)
      }
    },
    initializeUserStatuses() {
      console.log(this.inputCrm);
      console.log(this.inputCrm.statuses);
      this.userStatuses = Array.isArray(this.inputCrm.statuses) ? [...this.inputCrm.statuses] : [];
    },
  },
  computed: {
    statusesList() {
      return [
        {text: this.$t('form.new'), value: 'new'},
        {text: this.$t('form.missed_call'), value: 'missed_call'},
        {text: this.$t('form.in_progress'), value: 'in_progress'},
        {text: this.$t('form.confirmed'), value: 'confirmed'},
        {text: this.$t('form.rejected'), value: 'rejected'},
        {text: this.$t('form.shipped'), value: 'shipped'},
        {text: this.$t('form.not_purchased'), value: 'not_purchased'},
        {text: this.$t('form.purchased'), value: 'purchased'},
      ];
    },
    ...mapGetters(["currentLanguage"]),
  },
  watch: {
    visible(newValue) {
      if (newValue) {
        this.initializeUserStatuses();
      }
    },
  },
};
</script>

<style scoped>
.cross__inner {
  position: absolute;
  top: 0;
  right: 0;
}
.task-list-container {
  padding: 20px;
}
</style>
