<template>
  <v-dialog
      v-model="visibility"
      max-width="800"
      content-class="cross__dialog"
      :persistent="changed"
      :transition="$dialogTransition"
      @click:outside="showConfirmDialog"
      scrollable
  >
    <v-card>
      <v-fade-transition>
        <v-progress-linear
            v-if="loading"
            absolute
            top
            indeterminate
            color="orange"
        ></v-progress-linear>
      </v-fade-transition>
      <v-slide-x-transition>
        <div class="cross__inner" v-if="!loading">
          <v-btn fab small elevation="0" @click="showConfirmDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-slide-x-transition>
      <v-card-title>
        <v-row v-if="!$vuetify.breakpoint.smAndDown" no-gutters justify="space-between">
        <span class="font-20" :class="{ 'text-body-1': $vuetify.breakpoint.xs }">{{
            $t('form.edit_crm')
          }}</span>
        </v-row>
        <v-slide-x-reverse-transition>
          <v-menu
              offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  class="cursor-pointer"
                  icon
                  v-on="on"
                  v-bind="attrs"
                  small
              >
                <v-icon color="grey lighten-1" dense>
                  mdi-dots-vertical
                </v-icon>
              </v-btn>
            </template>
            <v-list class="pa-0" dense>
              <v-list-item
                  @click="openDeleteUserDialog(inputCrm)"
                  link
                  class="pa-0"
              >
                <v-row no-gutters>
                  <v-btn text>
                    {{ $t('form.deleteConfirmDialog.delete_dialog_title') }} {{ 'CRM' }}
                    <v-icon class="ml-2" color="red lighten-1" dense>
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </v-row>
              </v-list-item>
              <v-list-item
                  @click="openCrmStatusDialog(crmItem)"
                  link
                  class="pa-0"
              >
                <v-row no-gutters class="d-flex justify-center">
                  <v-btn text>
                    {{ $t('form.statuses') }}
                  </v-btn>
                </v-row>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-slide-x-reverse-transition>
      </v-card-title>
      <v-card-text class="py-5">
        <div>
          <v-row>
            <v-col cols="12">
              <v-text-field
                  outlined
                  dense
                  color="primary"
                  v-model="inputCrm.name"
                  :label="$t('form.title')"
                  :maxlength="50"
                  :error-messages="nameErrors"
                  hide-details="auto"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                  outlined
                  dense
                  color="primary"
                  v-model="inputCrm.url"
                  :label="'URL'"
                  :maxlength="50"
                  :error-messages="urlErrors"
                  hide-details="auto"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                  outlined
                  dense
                  color="primary"
                  v-model="inputCrm.input_token"
                  :label="'TOKEN'"
                  :maxlength="50"
                  :error-messages="inputTokenErrors"
                  hide-details="auto"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                  outlined
                  dense
                  color="primary"
                  v-model="inputCrm.output_token"
                  :label="'TOKEN'"
                  :maxlength="50"
                  :error-messages="outputTokenErrors"
                  hide-details="auto"
              />
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-row no-gutters justify="space-between">
          <v-col cols="6" class="pr-1">
            <v-btn
                dark
                block
                class="br-5 text-transform-none"
                @click="showConfirmDialog"
            >{{ $t("btn.cancel") }}
            </v-btn
            >
          </v-col>
          <v-col cols="6" class="pl-1">
            <v-btn
                block
                class="btn-orange-bg text-transform-none br-5"
                :disabled="!changed"
                :loading="loading"
                @click="onUpdate()"
            >{{ $t("btn.update") }}
            </v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <ConfirmLeftDialog
        :visible="visibleConfirm"
        @close="visibleConfirm = false"
        @delete="onClose"
    />
    <deleteConfirmDialog
        :visible="deleteCrmDialog"
        @close="deleteCrmDialog = false"
        @delete="deleteCrm"
    />
    <statuses-list-dialog
        :visible="crmStatusDialog"
        @close="crmStatusDialog = false"
        :inputCrm="crmItem"
    />
  </v-dialog>
</template>

<script>
import {validationMixin} from "vuelidate";
import {required, email} from "vuelidate/lib/validators";
import {validationForTitle, validationForPhoneNumber, validationForEmail} from "@/mixins/helpers";
import ConfirmLeftDialog from "@/components/dialog/ConfirmLeftDialog.vue";
import dialogMixin from "@/mixins/dialogMixin";
import notifications from "@/mixins/notifications";
import _ from "lodash";
import usersService from "@/services/request/users/usersService";
import DeleteConfirmDialog from "@/components/dialog/deleteConfirmDialog.vue";
import phoneMask from "@/validators/phoneMask";
import crmService from "@/services/request/crm/crmService";
import StatusesListDialog from "@/components/adminPanel/statusesListDialog.vue";

export default {
  name: "CrmEditDialog",

  data: () => ({
    loading: false,
    changed: false,
    visibleConfirm: false,
    inputCrm: {},
    copyCrm: {},
    rolesList: [],
    deleteCrmDialog: false,
    deletedCrm: {},
    crmStatusDialog: false,
    addedCrm: '',
  }),

  mixins: [dialogMixin, validationMixin, notifications],

  validations: {
    inputCrm: {
      name: {required},
      input_token: {required},
      output_token: {required},
      url: {required},
    },
  },

  components: {
    StatusesListDialog,
    DeleteConfirmDialog,
    ConfirmLeftDialog,
  },

  props: {
    crmItem: {
      required: false,
      default: () => ({}),
    },
  },
  async mounted() {
    await this.onMounted();
  },

  methods: {
    async onMounted() {
      this.loading = true;
      this.inputCrm = _.cloneDeep(this.crmItem);
      this.copyCrm = _.cloneDeep(this.inputCrm);
      this.loading = false;
    },
    openDeleteUserDialog(item) {
      this.deleteCrmDialog = true;
      this.deletedCrm = item;
    },
    openCrmStatusDialog(item) {
      this.crmStatusDialog = true;
    },
    deleteUser() {
      this.$emit('deleteCrm', this.deletedCrm);
    },
    onClose() {
      this.$v.$reset();
      this.$emit("close");
    },
    showConfirmDialog() {
      this.changed ? (this.visibleConfirm = true) : this.onClose();
    },
    async onUpdate() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        try {
          const formData = new FormData();
          formData.append("_method", 'patch');
          const changedData = this.$getChangedData(this.inputCrm, this.copyCrm);
          changedData.forEach((data) => {
            formData.append(data.field, data.value);
          });
          const crm = await crmService.updateCrm(this.inputCrm.id, formData);
          this.copyCrm = {...this.inputCrm};
          this.inputCrm = {...this.copyCrm};

          this.$emit("updateCrm", crm);
          this.setSuccessNotification(this.$t("profile.successfully_updated"));
          this.onClose();
        } catch (e) {
          this.setErrorNotification(e.message);
        } finally {
          this.loading = false;
        }
      }
    },
    deleteCrm() {
      this.$emit('deleteCrm', this.deletedCrm);
    },
  },
  watch: {
    inputCrm: {
      deep: true,
      handler() {
        this.changed = !_.isEqual(this.copyCrm, this.inputCrm);
      },
    },
  },
  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.inputCrm.name.$dirty) {
        return errors;
      }
      !this.$v.inputCrm.name.required &&
      errors.push(this.$t("form.errors.TitleRequired"));
      return errors;
    },
    urlErrors() {
      const errors = [];
      if (!this.$v.inputCrm.url.$dirty) {
        return errors;
      }
      !this.$v.inputCrm.url.required &&
      errors.push(this.$t("form.errors.UrlRequired"));
      return errors;
    },
    inputTokenErrors() {
      const errors = [];
      if (!this.$v.inputCrm.input_token.$dirty) {
        return errors;
      }
      !this.$v.inputCrm.input_token.required &&
      errors.push(this.$t("form.errors.inputTokenRequired"));
      return errors;
    },
    outputTokenErrors() {
      const errors = [];
      if (!this.$v.inputCrm.output_token.$dirty) {
        return errors;
      }
      !this.$v.inputCrm.output_token.required &&
      errors.push(this.$t("form.errors.outputTokenRequired"));
      return errors;
    },
  }
};
</script>