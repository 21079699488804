<template>
  <v-dialog
      v-model="visibility"
      max-width="800"
      content-class="cross__dialog"
      :persistent="changed"
      :transition="$dialogTransition"
      @click:outside="showConfirmDialog"
      scrollable
  >
    <v-card>
      <v-fade-transition>
        <v-progress-linear
            v-if="loading"
            absolute
            top
            indeterminate
            color="orange"
        ></v-progress-linear>
      </v-fade-transition>
      <v-slide-x-transition>
        <div class="cross__inner" v-if="!loading">
          <v-btn fab small elevation="0" @click="showConfirmDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-slide-x-transition>
      <v-card-title>
        <v-row v-if="!$vuetify.breakpoint.smAndDown" no-gutters justify="space-between">
        <span class="font-20" :class="{ 'text-body-1': $vuetify.breakpoint.xs }">{{
            $t('form.edit_user')
          }}</span>
        </v-row>
        <v-slide-x-reverse-transition>
          <v-menu
              offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  class="cursor-pointer"
                  icon
                  v-on="on"
                  v-bind="attrs"
                  small
              >
                <v-icon color="grey lighten-1" dense>
                  mdi-dots-vertical
                </v-icon>
              </v-btn>
            </template>
            <v-list class="pa-0" dense>
              <v-list-item
                  @click="openDeleteUserDialog(inputUser)"
                  link
                  class="pa-0"
              >
                <v-row no-gutters>
                  <v-btn text>
                    {{ $t('btn.delete_user') }}
                  </v-btn>
                </v-row>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-slide-x-reverse-transition>
      </v-card-title>
      <v-card-text class="py-5">
        <div>
          <v-row>
            <v-col cols="12">
              <v-text-field
                  outlined
                  dense
                  color="primary"
                  v-model="inputUser.name"
                  :label="$t('users.name')"
                  :maxlength="25"
                  hide-details="auto"
                  :error-messages="nameErrors"
                  @keypress="validationForTitle($event)"
                  @paste="validationForTitle($event)"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                  outlined
                  dense
                  color="primary"
                  v-model="inputUser.email"
                  :label="$t('form.email')"
                  :maxlength="25"
                  hide-details="auto"
                  @input="validationForEmail($event)"
                  :error-messages="emailErrors"
              />
          </v-col>
            <v-col cols="12">
              <v-text-field
                  outlined
                  dense
                  color="primary"
                  v-model="formattedPhoneNumber"
                  :label="$t('form.phone_number')"
                  :maxlength="10"
                  hide-details="auto"
                  v-on:keypress="validationForPhoneNumber($event)"
                @paste="validationForPhoneNumber($event)"
                
                :error-messages="phoneErrors"
                  prefix="+38"
              />
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                  :items="rolesList"
                  v-model="inputUser.role"
                  :item-value="'name'"
                  :item-text="'name'"
                  :label="$t('form.role')"
                  dense
                  hide-details
                  outlined
              />
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-row no-gutters justify="space-between">
          <v-col cols="6" class="pr-1">
            <v-btn
                dark
                block
                class="br-5 text-transform-none"
                @click="showConfirmDialog"
            >{{ $t("btn.cancel") }}</v-btn
            >
          </v-col>
          <v-col cols="6" class="pl-1">
            <v-btn
                block
                class="btn-orange-bg text-transform-none br-5"
                :disabled="!changed"
                :loading="loading"
                @click="onUpdate()"
            >{{ $t("btn.update") }}</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <ConfirmLeftDialog
        :visible="visibleConfirm"
        @close="visibleConfirm = false"
        @delete="onClose"
    />
    <deleteConfirmDialog
        :visible="deleteUserDialog"
        @close="deleteUserDialog = false"
        @delete="deleteUser"
    />
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import { validationForTitle, validationForPhoneNumber,validationForEmail } from "@/mixins/helpers";
import ConfirmLeftDialog from "@/components/dialog/ConfirmLeftDialog.vue";
import dialogMixin from "@/mixins/dialogMixin";
import notifications from "@/mixins/notifications";
import _ from "lodash";
import usersService from "@/services/request/users/usersService";
import DeleteConfirmDialog from "@/components/dialog/deleteConfirmDialog.vue";
import phoneMask from "@/validators/phoneMask";

export default {
  name: "UserEditDialog",
  
  data: () => ({
    loading: false,
    changed: false,
    visibleConfirm: false,
    inputUser: {},
    copyUser: {},
    rolesList: [],
    deleteUserDialog: false,
    deletedUser: {}
  }),

  mixins: [dialogMixin, validationMixin, notifications],

  validations: {
  inputUser: {
    name: { required },
    phone_number: { required, phoneMask },
    email: { required, email }, 
  },
},

  components: {
    DeleteConfirmDialog,
    ConfirmLeftDialog,
  },

  props: {
    user: {
      required: false,
      default: () => ({}),
    },
  },

  computed: {
    formattedPhoneNumber: {
  get() {
    return this.inputUser.phone_number ? this.inputUser.phone_number.replace(/^\+38/, '') : '';
  },
  set(value) {
    this.inputUser.phone_number = value ? `+38${value}` : ''; 
  }
},
    nameErrors() {
      const errors = [];
      if (!this.$v.inputUser.name.$dirty) {
        return errors;
      }
      !this.$v.inputUser.name.required &&
      errors.push(this.$t("form.errors.NameRequired"));
      return errors;
    },
    phoneErrors() {
  const errors = [];
  if (!this.$v.inputUser.phone_number.$dirty) { 
    return errors;
  }
  if (!this.$v.inputUser.phone_number.required) { 
    errors.push(this.$t("form.errors.PhoneNumberRequired"));
  }
  !this.$v.inputUser.phone_number.phoneMask && errors.push(this.$t("form.errors.PhoneNumberMustBeLike")); // змінено з user_number на phone_number
  return errors;
},
emailErrors() {
      const errors = [];
      if (!this.$v.inputUser.email.$dirty) return errors;
      !this.$v.inputUser.email.required &&
        errors.push(this.$t("form.errors.EmailRequired"));
      !this.$v.inputUser.email.email &&
        errors.push(this.$t("form.errors.EmailMustBeValid"));
      return errors;
    },
  },

  async mounted() {
    await this.onMounted();
  },

  methods: {
    validationForEmail,
    validationForTitle,
    validationForPhoneNumber,
    validatePhoneNumber(event) {
      const charCode = (event.which) ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        event.preventDefault();
        return false;
      }
      if (this.formattedPhoneNumber.length >= 10) {
        event.preventDefault();
        return false;
      }
      return true;
    },

    validatePhonePaste(event) {
      event.preventDefault();
      const pastedText = (event.clipboardData || window.clipboardData).getData('text');
      const numbersOnly = pastedText.replace(/\D/g, '');
      
      const maxLength = 10;
      const truncated = numbersOnly.slice(0, maxLength);
      
      this.formattedPhoneNumber = truncated;
    },

    async onMounted() {
      await this.fetchRoles();
      this.loading = true;
      this.inputUser = _.cloneDeep(this.user);
      if (this.inputUser.phone_number && !this.inputUser.phone_number.startsWith('+38')) {
        this.inputUser.phone_number = `+38${this.inputUser.phone_number}`;
      }
      this.copyUser = _.cloneDeep(this.inputUser);
      this.loading = false;
    },
    openDeleteUserDialog(item) {
      this.deleteUserDialog = true;
      this.deletedUser = item;
    },
    deleteUser() {
      this.$emit('deleteUser', this.deletedUser);
    },
    onClose() {
      this.$v.$reset();
      this.$emit("close");
    },
    async fetchRoles() {
      try {
        this.rolesList = await usersService.getRoles();
      } catch (e) {
        console.log(e);
      }
    },
    showConfirmDialog() {
      this.changed ? (this.visibleConfirm = true) : this.onClose();
    },
    async onUpdate() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        try {
          const formData = new FormData();
          formData.append("_method", 'patch');
          const changedData = this.$getChangedData(this.inputUser, this.copyUser);
          changedData.forEach((data) => {
            formData.append(data.field, data.value);
          });
          const user = await usersService.updateUser(this.inputUser.id, formData);
          this.copyUser = {...this.inputUser};
          this.inputUser = {...this.copyUser};
          
          this.$emit("updateUser", user);
          this.setSuccessNotification(this.$t("profile.successfully_updated"));
          this.onClose();
        } catch (e) {
          this.setErrorNotification(e.message);
        } finally {
          this.loading = false;
        }
      }
    },
  },
  watch: {
    inputUser: {
      deep: true,
      handler() {
        this.changed = !_.isEqual(this.copyUser, this.inputUser);
      },
    },
  },
};
</script>