<template>
  <AdminPanelComponent />
</template>


<script>

import AdminPanelComponent from "@/components/adminPanel/adminPanelComponent.vue";

export default {
  name: "AdminPanelView",
  components: {
    AdminPanelComponent,
  },
};
</script>